import styled from 'styled-components'

import breakpoints from '../constants/ui/breakpoints'

// 48px, 38px, 26px, 20px, 18px, 16px

export const TitleL = styled.h1`
  font-size: 3rem; //48
  line-height: 1.3;
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 700};

  @media (max-width: ${breakpoints.screenSM}) {
    font-size: 2.375rem; //38
  }
`

export const TitleM = styled.h2`
  font-size: 2.375rem; //38
  line-height: 1.3;
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 700};

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 2rem; //32
  }
`

export const TitleS = styled.h3`
  font-size: 1.625rem; //26
  line-height: 1.3;
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 700};
`

export const BodyL = styled.p`
  font-size: 1.25rem; //20
  line-height: 1.6;
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};
`

export const BodyM = styled.p`
  font-size: 1.125rem; //18
  line-height: 1.6;
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};
`

export const BodyS = styled.p`
  font-size: 1rem; //16
  line-height: 1.6;
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};
`

export const BodyXS = styled.p`
  font-size: 0.875rem; //14
  line-height: 1.6;
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};
`

export const BodyXXS = styled.p`
  font-size: 0.75rem; //12
  line-height: 1.6;
  color: ${({ theme, color }) => color ?? theme.textPrimary};
  font-weight: ${({ weight }) => weight ?? 400};
`
