import styled from 'styled-components'

import breakpoints from '../constants/ui/breakpoints'
import { MAIN_CONTAINER_WIDTH, HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '../constants/ui/elements'

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  color: ${({ theme }) => theme.textPrimary};
  width: 100%;
  overflow-x: hidden;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap};
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth ?? MAIN_CONTAINER_WIDTH};
  min-height: ${({ minHeight }) => minHeight};
  padding: ${({ padding }) => padding};
  margin: 0 auto;

  @media (max-width: ${breakpoints.screenMD}) {
    flex-direction: ${({ columnReverse, mobileColumn }) => (columnReverse ? 'column-reverse' : mobileColumn ? 'column' : 'row')};
    justify-content: ${({ mobileJustifyContent }) => mobileJustifyContent};
    align-items: ${({ mobileAlignItems }) => mobileAlignItems};
    gap: ${({ mobileGap }) => mobileGap};
    max-width: ${({ mobileMaxWidth }) => mobileMaxWidth ?? MAIN_CONTAINER_WIDTH};
    min-height: ${({ mobileMinHeight }) => mobileMinHeight};
    padding: ${({ mobilePadding }) => mobilePadding};
  }
`

export const Section = styled.div`
  padding: ${({ isCoverSection }) => (isCoverSection ? `calc(${HEADER_HEIGHT} + 2.5rem) 1rem 5rem` : '5rem 1rem')};
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'left')};
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.backgroundPrimary};

  @media (max-width: ${breakpoints.screenSM}) {
    padding: ${({ isCoverSection }) => (isCoverSection ? `calc(${HEADER_MOBILE_HEIGHT} + 1.5rem) 1rem 3rem` : '3rem 1rem')};
  }
`

export const ArrowCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.backgroundPrimary};
  border-radius: 50%;
  min-width: 2.25rem;
  height: 2.25rem;
`
