import { useState, useEffect } from 'react'
import axios from 'axios'

import { ExclusiveCountryCode, TranslatedCountryCode } from '../constants/data/exclusive'

const useIsExclusive = () => {
  const [isExclusive, setIsExclusive] = useState(false)
  const [isTranslated, setIsTranslated] = useState(false)
  const [countryCode, setCountryCode] = useState('')

  const getExclusive = async () => {
    try {
      const {
        data: { countryISOCode },
      } = await axios.get('https://www.1home.io/xapi/ip')

      if (Object.keys(ExclusiveCountryCode).includes(countryISOCode)) {
        setIsExclusive(true)
        setCountryCode(countryISOCode)
      }

      if (Object.keys(TranslatedCountryCode).includes(countryISOCode)) {
        setIsTranslated(true)
        setCountryCode(countryISOCode)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getExclusive()
  }, [])

  return { isExclusive, isTranslated, countryCode }
}

export default useIsExclusive
