const colors = {
  black: '#0d1116',
  white: '#ffffff',
  grey_600: '#16171e',
  grey_500: '#1f2025',
  grey_400: '#bababa',
  grey_300: '#dce0e0',
  grey_200: '#e6e6e6',
  grey_100: '#f5f5f5',
  green_500: '#008996',
  green_400: '#b4e0e1',
  green_300: '#e3f2f2',
  accent_500: '#52e8c1',
  accent_400: '#d2f5eb',
  orange_500: '#f89f1b',
  orange_400: '#ffe7c3',
  red_500: '#a11c2d',
  purple_500: '#544dc9',
  purple_400: '#ccc9fa',
  purple_300: '#f2f1fe',
  transparent: 'transparent',
}

const shadows = {
  primary: '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 10px 44px rgba(0, 0, 0, 0.07)',
}

export const theme = {
  // font
  fontPrimary: 'Inter',
  // background
  backgroundPrimary: colors.white,
  backgroundSecondary: colors.green_300,
  backgroundTertiary: colors.green_400,
  backgroundLight: colors.grey_100,
  backgroundDark: colors.black,
  backgroundSemiDarkPrimary: colors.grey_600,
  backgroundSemiDarkSecondary: colors.grey_500,
  backgroundHighlight: colors.green_500,
  backgroundExtraHighlight: colors.accent_500,
  backgroundWarning: colors.orange_400,
  backgroundInfo: colors.purple_300,
  // text
  textPrimary: colors.black,
  textSecondary: colors.grey_400,
  textHighlight: colors.green_500,
  textExtraHighlight: colors.accent_500,
  textLight: colors.white,
  textWarning: colors.orange_500,
  textInfo: colors.purple_500,
  // button
  buttonPrimary: colors.green_500,
  buttonSecondary: colors.white,
  buttonDark: colors.black,
  buttonLight: colors.white,
  buttonInfo: colors.purple_500,
  buttonOutline: colors.transparent,
  // switch
  switchBackground: colors.grey_100,
  switchPromoBackground: colors.grey_300,
  // border
  borderPrimary: colors.grey_200,
  borderSecondary: colors.green_300,
  borderTertiary: colors.grey_400,
  borderLight: colors.white,
  borderHighlight: colors.green_500,
  borderExtraHighlight: colors.accent_500,
  borderDark: colors.black,
  borderSemiDark: colors.grey_500,
  borderWarning: colors.orange_500,
  borderInfo: colors.purple_500,
  // box shadow
  boxShadowPrimary: shadows.primary,
}
