import styled from 'styled-components'
import { Link } from 'gatsby'
import { LocalizedLink } from 'gatsby-plugin-i18n-l10n'

import {
  ButtonPrimaryAttributes,
  ButtonSecondaryAttributes,
  ButtonDarkAttributes,
  ButtonLightAttributes,
  ButtonInfoAttributes,
  ButtonOutlineAttributes,
} from './ButtonStyled'

export const LinkStyled = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.textHighlight};
  font-weight: 500;

  :hover {
    opacity: 0.6;
  }
`

export const LinkAsButtonPrimary = styled.a`
  ${ButtonPrimaryAttributes}
`

export const LinkAsButtonSecondary = styled.a`
  ${ButtonSecondaryAttributes}
`

export const LinkAsButtonDark = styled.a`
  ${ButtonDarkAttributes}
`

export const LinkAsButtonLight = styled.a`
  ${ButtonLightAttributes}
`

export const LinkAsButtonInfo = styled.a`
  ${ButtonInfoAttributes}
`
export const LinkAsButtonOutline = styled.a`
  ${ButtonOutlineAttributes}
`

export const LinkGatsbydStyled = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.textHighlight};
  font-weight: 500;

  :hover {
    opacity: 0.6;
  }
`

export const LinkAsButtonGatsbyPrimary = styled(Link)`
  ${ButtonPrimaryAttributes}
`

export const LinkAsButtonGatsbySecondary = styled(Link)`
  ${ButtonSecondaryAttributes}
`

export const LinkAsButtonGatsbyDark = styled(Link)`
  ${ButtonDarkAttributes}
`

export const LinkAsButtonGatsbyLight = styled(Link)`
  ${ButtonLightAttributes}
`

export const LinkAsButtonGatsbyInfo = styled(Link)`
  ${ButtonInfoAttributes}
`

export const LinkAsButtonGatsbyOutline = styled(Link)`
  ${ButtonOutlineAttributes}
`

export const LinkLocalizedStyled = styled(LocalizedLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.textHighlight};
  font-weight: 500;

  :hover {
    opacity: 0.6;
  }
`

export const LinkAsButtonLocalizedPrimary = styled(LocalizedLink)`
  ${ButtonPrimaryAttributes}
`

export const LinkAsButtonLocalizedSecondary = styled(LocalizedLink)`
  ${ButtonSecondaryAttributes}
`

export const LinkAsButtonLocalizedDark = styled(LocalizedLink)`
  ${ButtonDarkAttributes}
`

export const LinkAsButtonLocalizedLight = styled(LocalizedLink)`
  ${ButtonLightAttributes}
`

export const LinkAsButtonLocalizedInfo = styled(LocalizedLink)`
  ${ButtonInfoAttributes}
`

export const LinkAsButtonLocalizedOutline = styled(LocalizedLink)`
  ${ButtonOutlineAttributes}
`
