import styled from 'styled-components'

import breakpoints from '../../constants/ui/breakpoints'

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.2s ease;
  z-index: ${({ zIndex }) => zIndex ?? 6};
`

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 1.25rem;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  padding: 2rem 2rem 2.5rem;
  text-align: center;
  z-index: 2;

  @media (max-width: ${breakpoints.screenSM}) {
    max-width: calc(100% - 1rem);
    padding: 1.5rem 1rem 2.5rem;
  }

  .title {
    margin-bottom: 3rem;
  }

  .btn {
    width: 90%;
    margin-bottom: 1rem;

    @media (max-width: ${breakpoints.screenSM}) {
      width: 100%;
    }
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
`
