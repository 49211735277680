import React, { useState, useEffect } from 'react'
import { Script } from 'gatsby'
import Cookies from 'universal-cookie'
import PropTypes from 'prop-types'

import { ThemeProvider } from 'styled-components'

import { theme } from '../constants/ui/theme'

import GlobalStyles from '../styles/GlobalStyles'
import { PageLayout } from '../styles/PageLayout'

import ExclusiveDistributorModal from './ExclusiveDistributorModal'

/**
 * The page layout including all global styles and theme provider,
 * used as a wrapper in gatsby-browser and gatsby-ssr.
 * @param {ComponentProps} props
 * @param {ChildNode} props.children
 * @returns {StatelessComponent}
 */
const Page = ({ children, location }) => {
  const [gtmTag, setGtmTag] = useState(undefined)

  const cookies = new Cookies()

  useEffect(() => {
    const savedTag = cookies.get('gtmTag')

    if (savedTag !== undefined) {
      setGtmTag(savedTag)
    }
  }, [])

  return (
    <>
      {gtmTag !== undefined && (
        <Script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmTag}');
          `}
        </Script>
      )}
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <PageLayout>{children}</PageLayout>
        <ExclusiveDistributorModal pathname={location.pathname} />
      </ThemeProvider>
    </>
  )
}

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Page
