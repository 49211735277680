import styled, { css } from 'styled-components'

import breakpoints from '../../constants/ui/breakpoints'
import { BUTTON_HEIGHT, BUTTON_SMALL_HEIGHT } from '../../constants/ui/elements'

export const ButtonAttributes = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  border: 0;
  border-radius: 2rem;
  padding: 0 2.5rem;
  height: ${({ small }) => (small ? BUTTON_SMALL_HEIGHT : BUTTON_HEIGHT)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  @media (max-width: ${breakpoints.screenSM}) {
    padding: 0 1.5rem;
    min-width: 90%;
  }

  :hover {
    opacity: 0.8;
  }
`

export const ButtonPrimaryAttributes = css`
  ${ButtonAttributes}
  color: ${({ theme }) => theme.textLight};
  background-color: ${({ theme }) => theme.buttonPrimary};
`

export const ButtonSecondaryAttributes = css`
  ${ButtonAttributes}
  color: ${({ theme }) => theme.textHighlight};
  background-color: ${({ theme }) => theme.buttonSecondary};
`

export const ButtonDarkAttributes = css`
  ${ButtonAttributes}
  color: ${({ theme }) => theme.textLight};
  background-color: ${({ theme }) => theme.buttonDark};
`

export const ButtonLightAttributes = css`
  ${ButtonAttributes}
  color: ${({ theme }) => theme.textPrimary};
  background-color: ${({ theme }) => theme.buttonLight};
`

export const ButtonInfoAttributes = css`
  ${ButtonAttributes}
  color: ${({ theme }) => theme.textLight};
  background-color: ${({ theme }) => theme.buttonInfo};
`

export const ButtonOutlineAttributes = css`
  ${ButtonAttributes}
  color: ${({ theme }) => theme.textDark};
  background-color: ${({ theme }) => theme.buttonOutline};
  border: 1px solid ${({ theme }) => theme.borderHighlight};
  will-change: background-color, color;
  transition: all 0.3s ease;

  &.active {
    background-color: ${({ theme }) => theme.buttonPrimary};
    color: ${({ theme }) => theme.textLight};
  }
`

export const ButtonPrimary = styled.button`
  ${ButtonPrimaryAttributes}
`

export const ButtonSecondary = styled.button`
  ${ButtonSecondaryAttributes}
`

export const ButtonDark = styled.button`
  ${ButtonDarkAttributes}
`

export const ButtonLight = styled.button`
  ${ButtonLightAttributes}
`

export const ButtonInfo = styled.button`
  ${ButtonInfoAttributes}
`

export const ButtonOutline = styled.button`
  ${ButtonOutlineAttributes}
`
